<template>
  <div class="laws-detail-wrapper">
    <van-nav-bar
      fixed
      title="法律法规"
      left-arrow
      :border="false"
      @click-left="onClickLeft"
    >
    </van-nav-bar>
    <div class="laws-detail-block">
      <div class="laws-info-title">基本信息</div>
      <label-value-field label="标题" :value="info.title" />
      <label-value-field label="适用部门" :value="info.depts" />
      <label-value-field label="类别" :value="info.typeName" />
      <label-value-field label="更新人" :value="info.uuserName" />
      <label-value-field label="发布机关" :value="info.office" />
      <label-value-field label="更新时间" :value="info.utime" />
      <label-value-field label="发布文号" :value="info.publishNo" />
      <label-value-field label="强制程度" :value="info.coerceDegreeName" />
      <label-value-field label="发布日期" :value="info.publishDate" />
      <label-value-field label="施行日期" :value="info.executeDate" />
      <label-value-field label="废止日期" :value="info.abolishDate" />
      <label-value-field label="辨识日期" :value="info.identifyDate" />
      <label-value-field label="复查日期" :value="info.reviewDate" />
      <label-value-field label="摘要" :value="info.summary" />
      <label-value-field label="备注" :value="info.note" />
      <label-value-field label="关键词" :value="info.keywords" />
      <label-value-field
        label="附件信息"
        type="file"
        :value="info.attachmentList"
      />
    </div>
    <div class="laws-detail-block">
      <div class="laws-info-title">正文</div>
      <p class="laws-info-content" v-html="info.content"></p>
    </div>
  </div>
</template>

<script>
import { getLawsInfo } from "@/api/psm";
export default {
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      coerceDegreeMap: {
        "0": "强制性",
        "1": "推荐性"
      },
      info: {}
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      if (!this.id) {
        this.onClickLeft();
        return;
      }
      // 获取详情
      getLawsInfo(this.id).then(res => {
        res.keywords = res.tagsVOList ? res.tagsVOList.join(",") : "";
        res.depts = Object.values(res.applyDepartments).join(",");
        res.coerceDegreeName = this.coerceDegreeMap[res.coerceDegree];
        this.info = res;
      });
    },
    onClickLeft() {
      this.$router.back();
    }
  }
};
</script>

<style lang="scss">
.laws-detail-wrapper {
  padding-top: 60px;
  box-sizing: border-box;
  background-color: #eceef2;
  .laws-info-title {
    font-size: 16px;
    color: #2e2e4d;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .laws-info-content {
    font-size: 14px;
    line-height: 20px;
  }
  .laws-detail-block {
    margin-bottom: 8px;
    padding: 16px;
    background-color: #fff;
  }
}
</style>
